require('./bootstrap');
require('./jquery.magnific-popup');
require("lottie-web");


$(document).ready(function() {


    $('form').on('submit', function(e) {
        let recaptchaBox = $('#recaptchaBox');

        if(recaptchaBox && grecaptcha.getResponse() == "") {
            e.preventDefault();
            alert(recaptchaBox.find(captchaAlert).html());
        }
    });

    $('.gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        closeOnContentClick: false,
        closeBtnInside: false,

        gallery: {
            enabled: true
        },
        callbacks: {
            elementParse: function(item) {
                if(item.el.hasClass('video-link')) {
                    item.src = '<video class="mx-auto d-block" width="80%" autoplay controls><source src="' + item.el.attr("href") + '" type="video/mp4"></video>';
                    item.type = 'inline';
                }
            }
        },

    });
});

$('.filter').click(function(){
    let targetFilter = $(this).attr("target-filter");
    $('.filter').removeClass("active");
    $(this).addClass("active");
    $.each($(".filtered-category"), function(index, el) {
        if( $(el).attr("data-category") !== targetFilter ){
            $(el).hide();
        } else {
            $(el).show();
        }
    });
});

var ua = navigator.userAgent.toLowerCase();
var isSafari;
if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
        isSafari = false;
    } else {
        isSafari = true;
    }
}

$('[slide-to]').click(function(){
    let slideTo = $(this).attr('slide-to');
    $('html, body').animate({
        scrollTop: $(slideTo).offset().top - 100
    }, 800);
});

$(function(){
    $.each($("p"), function(index, el) {
        if($(el).html() == ''){
            $(el).remove();
        }
    });
})